import { NewsletterService } from "services/newsletter.service";
import { useState } from "react";
import { IAxiosServerResponse, IServerResponse } from "shared";
import { showToast } from "functions/show-toast.function";

import styles from "./newsletter.module.scss";
import Title from "antd/es/typography/Title";
import { Button, Input } from "antd";
import { Mail } from "react-feather";

export const Newsletter = (): JSX.Element => {
    const [email, setEmail] = useState<string>("");

    const handleEmailSubmit = (e: any): void => {
        e?.preventDefault();
        subscribeToBetaNews();
    };

    const subscribeToBetaNews = (): void => {
        NewsletterService.signUpToNewsletter(email, "BETA")
            .then((m: IServerResponse) => {
                showToast("success", m.message);
            })
            .catch((e: IAxiosServerResponse) => {
                showToast("error", `Server responded with code <${e.response.data.status}>: ` + e.response.data.message);
            })
            .finally(() => {
                setEmail("");
            });
    };

    return (
        <section>
            <div className={styles.newsletterBox}>
                <div className={styles.newsletterPadding}>

                    {/* CTA box */}
                    <div className={styles.cta} data-aos="zoom-y-out">

                        {/* Background illustration */}
                        <div className={styles.illustration} aria-hidden="true" data-aos="zoom-out" data-aos-delay="500" data-aos-duration="3000">
                            <svg width="428" height="328" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <radialGradient cx="35.542%" cy="34.553%" fx="35.542%" fy="34.553%" r="96.031%" id="ni-a">
                                        <stop stopColor="var(--primary-blue-light)" offset="0%" />
                                        <stop stopColor="var(--primary-blue)" offset="44.317%" />
                                        <stop stopColor="#333" offset="100%" />
                                    </radialGradient>
                                </defs>
                                <g fill="none" fillRule="evenodd">
                                    <g fill="#FFF">
                                        <ellipse fillOpacity=".04" cx="185" cy="15.576" rx="16" ry="15.576" />
                                        <ellipse fillOpacity=".24" cx="100" cy="68.402" rx="24" ry="23.364" />
                                        <ellipse fillOpacity=".12" cx="29" cy="251.231" rx="29" ry="28.231" />
                                        <ellipse fillOpacity=".64" cx="29" cy="251.231" rx="8" ry="7.788" />
                                        <ellipse fillOpacity=".12" cx="342" cy="31.303" rx="8" ry="7.788" />
                                        <ellipse fillOpacity=".48" cx="62" cy="126.811" rx="2" ry="1.947" />
                                        <ellipse fillOpacity=".12" cx="78" cy="7.072" rx="2" ry="1.947" />
                                        <ellipse fillOpacity=".64" cx="185" cy="15.576" rx="6" ry="5.841" />
                                    </g>
                                    <circle fill="url(#ni-a)" cx="276" cy="237" r="200" />
                                </g>
                            </svg>
                        </div>

                        <div className={styles.ctaBox}>

                            {/* CTA content */}
                            <div className={styles.ctaContent}>
                                <Title level={3} className="text-white mt-3">Want access to early beta?</Title>
                                <p className="text-gray mb-4">Leave your email and we&apos;ll contact you.</p>

                                {/* CTA form */}
                                <form className="w-full"
                                    onSubmit={handleEmailSubmit}
                                >
                                    <div
                                        className={styles.formControl}
                                    >
                                        <Input
                                            type="email"
                                            className={styles.emailInput}
                                            placeholder="hello@example.com"
                                            aria-label="hello@example.com"
                                            size="large"
                                            value={email}
                                            onChange={e => { setEmail(e.target.value); }}
                                        />
                                        <Button
                                            size="large"
                                            type="primary"
                                            className="ml-3"
                                            onClick={handleEmailSubmit}
                                            icon={<Mail size="15" className="anticon" />}
                                        >Let me know</Button>
                                    </div>
                                    {/* Success message */}
                                    {/* <p className="text-sm text-gray-400 mt-3">Thanks for subscribing!</p> */}
                                    <p className="text-sm text-gray mt-3">No spam. You can unsubscribe at any time.</p>
                                </form>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </section>
    );
};
