import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { routeUrls } from "routes/routes-urls";
import { useEffect } from "react";

interface AnonymousRouteAttrs {
    children: JSX.Element | JSX.Element[];
}

export const AnonymousRoute = ({ children }: AnonymousRouteAttrs): JSX.Element => {
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate(routeUrls.alreadyLoggedIn);
        }
    }, []);

    return (
        user
            ? <></>
            : <>{children}</>
    );
};
