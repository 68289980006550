import { configuration } from "config/configuration";

export const defaultHeaders = (): Record<string, string> => {
    const headers: Record<string, string> = {};

    if (localStorage.getItem(configuration.storageKey.AUTH_TOKEN)) {
        headers.Authorization = "Bearer " + (localStorage.getItem(
            configuration.storageKey.AUTH_TOKEN,
        ) as string);
    }

    headers["content-type"] = "application/json";
    // headers["host-origin"] = window.location.origin;
    return headers;
};
