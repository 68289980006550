import { Modal } from "components/utils/modal";
import { useState } from "react";
import { Calendar, PlayCircle } from "react-feather";

import { Button } from "antd";
import styles from "./hero.module.scss";
import Title from "antd/es/typography/Title";

export const Hero = (): JSX.Element => {
    const [videoModalOpen, setVideoModalOpen] = useState<boolean>(false);

    return (
        <section className="relative">

            {/* Illustration behind hero content */}
            <div className={styles.illustration} aria-hidden="true">
                <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
                            <stop stopColor="#FFF" offset="0%" />
                            <stop stopColor="#EAEAEA" offset="77.402%" />
                            <stop stopColor="#DFDFDF" offset="100%" />
                        </linearGradient>
                    </defs>
                    <g fill="url(#illustration-01)" fillRule="evenodd">
                        <circle cx="1232" cy="128" r="128" />
                        <circle cx="155" cy="443" r="64" />
                    </g>
                </svg>
            </div>

            <div className={styles.heroBox}>

                {/* Hero content */}
                <div className={styles.heroContent}>

                    {/* Section header */}
                    <div className={styles.header}>
                        <Title level={3} className={styles.websiteName + " mt-0"} data-aos="zoom-y-out" data-aos-delay="0">Anonymized.app</Title>
                        <Title level={1} className={styles.title} data-aos="zoom-y-out">Truly <span className={styles.gradient}>anonymous</span> workplace surveys</Title>
                        <div className="">
                            <Title level={4} className="text-gray" data-aos="zoom-y-out" data-aos-delay="150">
                                Harnessing the power of AI to deliver you honest, specific, and
                                <br />truly anonymous workplace reviews.
                                {/* <br /><span className={"underline underline-offset-5 text-2xl " + caveat.className}>truly anonymous</span> workplace reviews. */}
                            </Title>
                            <div className={styles.scheduleDemo} data-aos="zoom-y-out" data-aos-delay="300">
                                <div>
                                    <a className="" href="https://calendly.com/anonymized/15min" target="_blank" rel="noreferrer">
                                        <Button
                                            size="large"
                                            type="primary"
                                            className={styles.scheduleDemoButton}
                                            icon={<Calendar size="20" className="anticon" />}
                                        >
                                            Schedule a demo
                                        </Button>
                                    </a>
                                </div>
                                {/* <div>
                                    <a className="btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4" href="#0">Learn more</a>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    {/* Hero image */}
                    <div>
                        <div className={styles.heroImage} data-aos="zoom-y-out" data-aos-delay="450">
                            <div className="flex-column d-jc">
                                <img
                                    className="mx-auto"
                                    src='/images/hero-image.png'
                                    width={768}
                                    height={432}
                                    alt="Main"
                                />
                                <svg className={styles.heroSvg} width="768" height="432" viewBox="0 0 768 432" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <defs>
                                        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="hero-ill-a">
                                            <stop stopColor="#FFF" offset="0%" />
                                            <stop stopColor="#EAEAEA" offset="77.402%" />
                                            <stop stopColor="#DFDFDF" offset="100%" />
                                        </linearGradient>
                                        <linearGradient x1="50%" y1="0%" x2="50%" y2="99.24%" id="hero-ill-b">
                                            <stop stopColor="#FFF" offset="0%" />
                                            <stop stopColor="#EAEAEA" offset="48.57%" />
                                            <stop stopColor="#DFDFDF" stopOpacity="0" offset="100%" />
                                        </linearGradient>
                                        <radialGradient cx="21.152%" cy="86.063%" fx="21.152%" fy="86.063%" r="79.941%" id="hero-ill-e">
                                            <stop stopColor="#4FD1C5" offset="0%" />
                                            <stop stopColor="#81E6D9" offset="25.871%" />
                                            <stop stopColor="#338CF5" offset="100%" />
                                        </radialGradient>
                                        <circle id="hero-ill-d" cx="384" cy="216" r="64" />
                                    </defs>
                                    <g fill="none" fillRule="evenodd">
                                        <circle fillOpacity=".04" fill="url(#hero-ill-a)" cx="384" cy="216" r="128" />
                                        <circle fillOpacity=".16" fill="url(#hero-ill-b)" cx="384" cy="216" r="96" />
                                        <g fillRule="nonzero">
                                            <use fill="#000" xlinkHref="#hero-ill-d" />
                                            <use fill="url(#hero-ill-e)" xlinkHref="#hero-ill-d" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <Button
                                className={styles.watchVideo}
                                size="large"
                                shape="round"
                                icon={<PlayCircle />}
                                onClick={(e) => { e.preventDefault(); e.stopPropagation(); setVideoModalOpen(true); }}
                                aria-controls="modal"
                            >
                                {/* <svg className="w-6 h-6 fill-current text-gray-400 group-hover:text-blue-600 shrink-0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0 2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z" />
                                    <path d="M10 17l6-5-6-5z" />
                                </svg> */}
                                <span className="ml-3">Watch the full video (2 min)</span>
                            </Button>
                        </div>

                        {/* Modal */}
                        <Modal
                            id="modal"
                            ariaLabel="modal-headline"
                            show={videoModalOpen}
                            handleClose={() => { setVideoModalOpen(false); }}
                        >
                            <div className="relative w-full h-full flex-ac">
                                <video className="absolute" width="1920" height="1080" loop controls>
                                    <source src="/videos/video.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </Modal>

                    </div>

                </div>

            </div>
        </section>
    );
};
