import { Features } from "components/features";
import { FeaturesBlocks } from "components/features-blocks";
import { Hero } from "components/hero";
import { Newsletter } from "components/newsletter";

export const HomePage = (): JSX.Element => {
    return (
        <>
            <Hero />
            <Features />
            <FeaturesBlocks />
            {/* <Testimonials /> */}
            <br />
            <br />
            <br />
            <Newsletter />
        </>
    );
};
