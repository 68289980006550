import { featureBlocks } from "./features-block.definition";
import styles from "./feature-blocks.module.scss";
import Title from "antd/es/typography/Title";

export const FeaturesBlocks = (): JSX.Element => {
    return (
        <section className="relative">

            {/* Section background (needs .relative class on parent and next sibling elements) */}
            <div className={styles.background} aria-hidden="true"></div>
            <div className={styles.verticalBar}></div>

            <div className={styles.content}>
                <div className={styles.padding}>

                    {/* Section header */}
                    <div className={styles.header}>
                        <Title level={2}>How is anonymity guaranteed?</Title>
                        <p className="text-gray text-xl">By pooling all the data together and anonymizing it with the power of AI, we provide safety and anonymity for participants, and honesty and transparency for the study directors.</p>
                    </div>

                    {/* Items */}
                    <div className={styles.blocks}>

                        {/* 6th item */}
                        {
                            featureBlocks.map(b =>
                                <div key={b.title} className={styles.block}>
                                    <svg className={styles.box} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                        <g fill="none" fillRule="evenodd">
                                            <rect className={styles.rect} width="64" height="64" rx="32" />
                                            {b.path}
                                        </g>
                                    </svg>
                                    <h4 className={styles.blockTitle}>{b.title}</h4>
                                    <p className="text-gray text-center">{b.content}</p>
                                </div>,
                            )
                        }

                    </div>

                </div>
            </div>
        </section>
    );
};
