import { Transition } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";

import styles from "./features.module.scss";
import Title from "antd/es/typography/Title";

interface ITab {
    title: string;
    description: string;
    image: string;
    imageElement: string;
}

const tabContent: ITab[] = [
    { title: "You create a survey", description: "Create a customised survey (single or multiple choice, likert, text...) and share it with your team", image: "/images/features-bg.png", imageElement: "/images/features-element.png" },
    { title: "Participants answer", description: "The participants answer honestly — they do that because they know it's anonymous — and speak their mind", image: "/images/features-bg.png", imageElement: "/images/features-element.png" },
    { title: "You ask questions about the results", description: "The data gets aggregated into a FAQ-like tool and a bunch of analytics", image: "/images/features-bg.png", imageElement: "/images/features-element.png" },
];

export const Features = (): JSX.Element => {
    const [tab, setTab] = useState<number>(0);

    const tabs = useRef<HTMLDivElement>(null);

    const heightFix = (): void => {
        // if (tabs.current && tabs.current.parentElement) tabs.current.parentElement.style.height = `${tabs.current.clientHeight}px`
    };

    useEffect(() => {
        heightFix();
    }, []);

    return (
        <section className="relative">

            {/* Section background (needs .relative class on parent and next sibling elements) */}
            <div className={styles.background} aria-hidden="true"></div>
            <div className={styles.line}></div>

            <div className={styles.features}>
                <div className={styles.featuresPadding}>

                    {/* Section header */}
                    <div className={styles.header}>
                        <Title level={2}>How does it work?</Title>
                        <p className="text-xl text-gray"><span className={styles.gradient}>Anonymized</span> aggregates all the data into a summary, and &#8212; instead of showing it &#8212; answers questions. In this way, you can learn the common voice.</p>
                    </div>

                    {/* Section content */}
                    <div className={styles.grid}>

                        {/* Content */}
                        <div className={styles.content} data-aos="fade-right">
                            <div className={styles.contentHeader}>
                                <Title level={3}>Powered by Natural Language Models</Title>
                                <p className="text-xl text-gray">Until now, managers could guess who wrote what, based on style and examples. We fixed that.</p>
                            </div>
                            {/* Tabs buttons */}
                            <div className={styles.tabsButtons}>
                                {
                                    tabContent.map((t: ITab, index: number) =>
                                        <a
                                            key={t.title}
                                            className={`${styles.tabContent} ${tab !== index ? styles.unselected : styles.selected}`}
                                            href="#0"
                                            onClick={(e) => { e.preventDefault(); setTab(index); }}
                                        >
                                            <div>
                                                <div className={styles.tabContentTitle}>{t.title}</div>
                                                <div className="text-gray">{t.description}</div>
                                            </div>
                                            <div className={styles.symbol}>
                                                <svg className={styles.symbolSvg} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
                                                </svg>
                                            </div>
                                        </a>,
                                    )
                                }
                            </div>
                        </div>

                        {/* Tabs items */}
                        <div
                            className={styles.tabItemBox}
                            data-aos="zoom-y-out"
                            ref={tabs}
                        >
                            <div className={styles.tabItem}>
                                {tabContent.map((t: ITab, index: number) =>
                                    <Transition
                                        key={t.title}
                                        show={tab === index}
                                        appear={true}
                                        className="w-full"
                                        enter="transition ease-in-out duration-700 transform order-first"
                                        enterFrom="opacity-0 translate-y-16"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in-out duration-300 transform absolute"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 -translate-y-16"
                                    >
                                        <div className="relative flex-inline flex-column">
                                            <img className={styles.tabItemImage}
                                                src={t.image}
                                                width={500}
                                                height="462"
                                                alt="Features bg"
                                            />
                                            <img className={styles.tabItemImageElement}
                                                src={t.imageElement}
                                                width={500}
                                                height="44"
                                                alt="Element"
                                                style={{ top: "30%" }}
                                            />
                                        </div>
                                    </Transition>,
                                )}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};
