import { Observable, catchError, tap, throwError } from "rxjs";
import { axiosRequest } from "services//axios.service";
import jwt_decode from "jwt-decode";
import sha256 from "sha256";
import { EndpointLabels } from "types";
import { LoginUser, AuthResponse, ILoginData, LoginResponse, ForgotPasswordData, ISignupData } from "shared";
import { configuration } from "config/configuration";

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class LoginService {
    public static get token (): string | null {
        const token: string | null = localStorage.getItem(
            configuration.storageKey.AUTH_TOKEN,
        );
        return token ?? null;
    }

    public static get user (): LoginUser | null {
        const token: string | null = LoginService.token;
        if (!token || token.length < 20) {
            return null;
        }
        const user: LoginUser = jwt_decode(token);

        return user;
    }

    public static clearCredentials = (): void => {
        localStorage.removeItem(configuration.storageKey.AUTH_TOKEN);
        localStorage.removeItem(configuration.storageKey.USER);
    };

    public static verifyToken = (): Observable<AuthResponse> => {
        const token: string | null = LoginService.token;
        if (!token) {
            return throwError(() => new Error("Invalid token"));
        }
        return axiosRequest<AuthResponse, void>("POST", "/auth/verify-token", EndpointLabels._)
            .pipe(
                tap((authResponse: AuthResponse) => {
                    // mixpanel.identify(authResponse.user_id);
                }),
            );
    };

    public static setCredentials = (token: string, user: LoginUser): void => {
        localStorage.setItem(configuration.storageKey.AUTH_TOKEN, token);
        localStorage.setItem(configuration.storageKey.USER, JSON.stringify(user));
    };

    public static logout = (): void => {
        LoginService.clearCredentials();
        // mixpanel.track("Logout");
    };

    public static login = (loginData: ILoginData): Observable<LoginResponse> => {
        loginData.password = sha256(loginData.password);
        return axiosRequest<LoginResponse, ILoginData>("POST", "/auth/login", EndpointLabels._, loginData, false)
            .pipe(
                tap((data: LoginResponse) => {
                    this.setCredentials(data.token, data.user);
                    // mixpanel.identify(data.user.gen);
                    // mixpanel.track("Login", { user: data.user });
                }),
                catchError((error: any) => {
                    throw new Error(error);
                }),
            );
    };

    public static signup = (
        signupData: ISignupData,
    ): Observable<LoginResponse> => {
        signupData.password = sha256(signupData.password);
        return axiosRequest<LoginResponse, ISignupData>("POST", "/auth/signup", EndpointLabels._, signupData)
            .pipe(
                tap((data: LoginResponse) => {
                    this.setCredentials(data.token, data.user);
                    // mixpanel.identify(data.user.gen);
                    // mixpanel.track("Signup", { user: data.user });
                }),
                catchError((error: AuthResponse) => {
                    throw new Error(error.reason);
                }),
            );
    };

    public static requestPassword = (loginData: ForgotPasswordData): Observable<void> => {
        return axiosRequest<void, ForgotPasswordData>("POST", "/auth/request-password", EndpointLabels._, loginData)
            .pipe(
                catchError((error: any) => {
                    throw new Error(error);
                }),
            );
    };

    public static resetPassword = (loginData: ILoginData): Observable<LoginResponse> => {
        return axiosRequest<LoginResponse, ForgotPasswordData>("POST", "/auth/reset-password", EndpointLabels._, loginData)
            .pipe(
                catchError((error: any) => {
                    throw new Error(error);
                }),
            );
    };
}
