import { Button, Checkbox, Divider, Input } from "antd";
import { PageWrapper } from "components/PageWrapper";
import { configuration } from "config/configuration";
import { useAuth } from "context/AuthContext";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { routeUrls } from "routes/routes-urls";
import { LoginService } from "services/login.service";
import { LoginResponse } from "shared";

import styles from "./login-page.module.scss";

export const LoginPage = (): JSX.Element => {
    const { setUser } = useAuth();
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>("");
    const [keepMeSignedIn, setKeepMeSignedIn] = useState<boolean>(true);
    const [password, setPassword] = useState<string>("");

    const handleSubmit = (e?: any): void => {
        e?.preventDefault();

        console.log(email, keepMeSignedIn, password);

        LoginService.login({ email, keepMeSignedIn, password })
            .subscribe((data: LoginResponse) => {
                setUser(data.user);
                const redirectTo: string = localStorage.getItem(configuration.storageKey.PRE_LOGIN) ?? routeUrls.onLogin;
                localStorage.removeItem(configuration.storageKey.PRE_LOGIN);
                navigate(redirectTo);
            });
    };

    return (
        <PageWrapper header={"Welcome back!"}>
            {/* Form */}
            <div className={styles.container}>
                <form onSubmit={handleSubmit}>
                    <div className="flex">
                        <div className="w-full mb-3">
                            <label className="" htmlFor="email">Email</label>
                            <Input
                                id="email"
                                type="email"
                                className=""
                                placeholder="Enter your email address"
                                required
                                value={email}
                                onChange={e => { setEmail(e.target.value); }}
                            />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-full mb-3">
                            <div className="flex-jb">
                                <label className="" htmlFor="password">Password</label>
                                <Link to={routeUrls.resetPassword} className="link text-sm">Having trouble signing in?</Link>
                            </div>
                            <Input id="password"
                                type="password"
                                className=""
                                placeholder="Enter your password"
                                required
                                value={password}
                                onChange={e => { setPassword(e.target.value); }}
                            />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-full mb-3">
                            <label className="flex items-center">
                                <Checkbox
                                    type="checkbox"
                                    className=""
                                    checked={keepMeSignedIn}
                                    onChange={e => { setKeepMeSignedIn(e.target.checked); }}
                                >
                                    Keep me signed in
                                </Checkbox>
                            </label>
                        </div>
                    </div>
                    <Button
                        htmlType="submit"
                        type="primary"
                        block
                        size="large"
                        className="">
                        Sign in
                    </Button>
                </form>
                <Divider className="text-gray">Or</Divider>

                <div className="text-gray text-center mt-6">
                    Don&apos;t have an account yet? <Link to={routeUrls.signup} className="link">Sign up</Link>
                </div>
            </div>
        </PageWrapper>
    );
};
