import { LoginPage } from "pages/auth/LoginPage";
import { SignupPage } from "pages/auth/SignupPage";
import { routeUrls } from "routes/routes-urls";
import { RouteDefinitions } from "types";

export const anonymousRoutes: RouteDefinitions = [
    { route: routeUrls.login, element: <LoginPage /> },
    // { route: routeUrls.signup, element: <><MobileLandingPage /></> },
    { route: routeUrls.signup, element: <SignupPage /> },
    // { route: routeUrls.forgotPassword, element: <ForgotPasswordPage /> },
    // { route: routeUrls.resetPassword, element: <ResetPasswordPage /> },
];
