import { configuration } from "config/configuration";
import { AuthContext } from "context/AuthContext";
import { userIsAdmin } from "functions/user-is-admin.function";
// import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { routeUrls } from "routes/routes-urls";
import { tap, catchError, of } from "rxjs";
import { LoginService } from "services/login.service";
import { SettingsService } from "services/settings.service";
import { LoginUser, IUserSettings, AuthResponse } from "shared";

const VALUE_NOT_SET = undefined;

interface AuthWrapperAttrs {
    children: JSX.Element | JSX.Element[];
}

export const AuthWrapper = ({
    children,
}: AuthWrapperAttrs): JSX.Element | null => {
    const [user, setUser] = useState<LoginUser | null | undefined>(VALUE_NOT_SET);
    // const [_, setSettings] = useAtom<IUserSettings | null, IUserSettings, void>(settingsAtom);
    const [_, setSettings] = useState<any>();

    useEffect(() => {
        const _user: LoginUser | null = LoginService.user;

        setUser(_user);
        if (!_user) {
            console.info("not a valid token / not logged in");
            return;
        }

        LoginService.verifyToken()
            .pipe(
                tap((authResponse: AuthResponse) => {
                    // mixpanel.identify(authResponse.user_id);
                }),
                catchError((response: AuthResponse) => {
                    logout(routeUrls.notLoggedIn);
                    return of(null);
                }),
            )
            .subscribe();
    }, []);

    useEffect(() => {
        if (user) {
            SettingsService.getSettings()
                .pipe(
                    tap((s: IUserSettings) => {
                        setSettings(s);
                    }),
                    catchError((error: any) => {
                        console.error("ERROR loading settings", error);
                        return of(null);
                    }),
                )
                .subscribe();
        } else {
            setSettings(configuration.defaultUserSettings);
        }
    }, [user?.id]);

    const logout = (redirectTo: string = routeUrls.onLogout): void => {
        setUser(null);
        LoginService.logout();
        // Force page reload to perform a proper redirect.
        // window.history.replaceState(null, "", redirectTo);
        window.location.href = redirectTo;
    };

    return (
        user === VALUE_NOT_SET
            ? null
            : <AuthContext.Provider value={{ user: user ?? null, logout, setUser, isAdmin: userIsAdmin(user) }}>
                {children}
            </AuthContext.Provider>
    );
};
