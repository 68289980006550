import { useEffect, useState } from "react";

import { Logo } from "./logo";
import { MobileMenu } from "./mobile-menu";
import { Link } from "react-router-dom";
import { routeUrls } from "routes/routes-urls";
import { useAuth } from "context/AuthContext";
import { Button } from "antd";

import styles from "./header.module.scss";

export const Header = (): JSX.Element => {
    const [top, setTop] = useState<boolean>(true);
    const { user, logout } = useAuth();
    const isDev: boolean = process.env.NODE_ENV === "development";

    // detect whether user has scrolled the page down by 10px
    const scrollHandler = (): void => {
        window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };

    useEffect(() => {
        scrollHandler();
        window.addEventListener("scroll", scrollHandler);
        return () => { window.removeEventListener("scroll", scrollHandler); };
    }, [top]);

    return (
        <header className={`${styles.header} ${!top ? styles.headerScrolled : ""}`}>
            <div className={styles.headerBox}>
                <div className={styles.bar}>
                    {/* Site branding */}
                    <div className={styles.logoBox}>
                        <Logo />
                    </div>

                    {/* Desktop navigation */}
                    {isDev && <>
                        <nav className={styles.desktop}>
                            {/* Desktop sign in links */}
                            {user
                                ? <ul className={styles.buttonList}>
                                    <li>
                                        <Link to={routeUrls.settings}>
                                            <Button type="primary">
                                                <span>{user.name}</span>
                                                <img src="/icons/user.svg" className="ml-3" height="25" width="25" alt="User settings" />
                                            </Button>
                                        </Link>
                                    </li>
                                    <li>
                                        <Button
                                            onClick={() => logout(routeUrls.homePage)}
                                        // className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
                                        >
                                            Log out
                                        </Button>
                                    </li>
                                </ul>
                                : <ul className={styles.buttonList}>
                                    <li>
                                        <Link to={routeUrls.login} >
                                            <Button>
                                                Sign in
                                            </Button>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={routeUrls.signup} >
                                            <Button type="primary">
                                                Signup
                                            </Button>
                                        </Link>
                                    </li>
                                </ul>
                            }

                        </nav>

                        <MobileMenu />
                    </>
                    }

                </div>
            </div>
        </header>
    );
};
