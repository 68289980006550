
export const routeUrlParams = {
    NEW_CONVO_ID: "new",
};

export const routeUrls = {
    homePage: "/",
    login: "/auth/login",
    forgotPassword: "/auth/forgot",
    resetPassword: "/auth/reset",
    signup: "/auth/signup",
    privacy: "/legal/privacy",
    terms: "/legal/terms",
    about: "/about",
    settings: "/not-yet-implemented-settings",

    onLogout: "",
    notLoggedIn: "",
    onLogin: "",
    onSignUp: "",
    alreadyLoggedIn: "",
};

routeUrls.onLogout = routeUrls.homePage + "?logged-out";
routeUrls.notLoggedIn = routeUrls.login + "?not-logged-in";
routeUrls.onLogin = routeUrls.homePage + "?welcome-back";
routeUrls.onSignUp = routeUrls.homePage + "?welcome";
routeUrls.alreadyLoggedIn = routeUrls.homePage + "?already-logged-in";
