import { PageWrapper } from "components/PageWrapper";

export const TermsOfServicePage = (): JSX.Element =>
    <PageWrapper header="Terms Of Service">
        <>
            By signing up you accept to the following:
            <ul>

                <li>Your email will be added to a mailing list, and you will receive periodic updates about the
                    website (max 1 per month). You can always unsubscribe by going to your settings.</li>
                <li>The website is provided "as is", without any warranty in the case of bugs, errors, unavailability
                    or data loss. But you are reading this, so here's a treat just for you.</li>
                <li>The continuity of this website is not assured. It is non-profit, and I'll try to keep it up, but
                    if losses are too big, it will be shut down.</li>
            </ul>
        </>
    </PageWrapper>;
