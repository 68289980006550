import axios from "axios";
import { _ENV_ } from "config/env";
import { IServerResponse, MailingListType } from "shared";

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class NewsletterService {
    public static signUpToNewsletter = (email: string, campaign: MailingListType): Promise<IServerResponse> => {
        return axios.post(`${_ENV_.apiUrl}/mailing-list`, { email, campaign })
            .then(a => a.data);
    };
}
