import React, { useContext } from "react";
import { IAuthContext } from "types";

export const AuthContext = React.createContext<IAuthContext>({
    user: null,
    isAdmin: false,
    setUser: () => null,
    logout: () => null,
});

export const useAuth = (): IAuthContext => useContext<IAuthContext>(AuthContext);
