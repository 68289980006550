import { Navigate, Route, Routes } from "react-router-dom";
import { RouteDefinition } from "types";
import { anonymousRoutes } from "routes/definitions/anonymous-routes";
import { notFoundRoute } from "routes/definitions/not-found-route";
import { privateRoutes } from "routes/definitions/private-routes";
import { publicRoutes } from "routes/definitions/public-routes";
import { AnonymousRoute } from "context/AnonymousRoute";
import { LoggedInRoute } from "context/LoggedInRoute";
import { redirectRoutes } from "./definitions/redirect-routes";
import { adminRoutes } from "./definitions/admin-routes";
import { AdminRoute } from "context/AdminRoute";

export const AppRoutes = (): JSX.Element => {
    return (
        <Routes>
            {redirectRoutes.map(r =>
                <Route key={r.from} path={r.from} element={<Navigate to={r.to} />} />,
            )}

            {/* Can access logged in and anonymous */}
            {publicRoutes.map((r: RouteDefinition) =>
                <Route path={r.route} key={r.route} element={r.element} />,
            )}

            {/* Can only access anonymously */}
            {anonymousRoutes.map((r: RouteDefinition) =>
                <Route path={r.route} key={r.route} element={<AnonymousRoute>{r.element}</AnonymousRoute>} />,
            )}

            {/* Can only access logged in */}
            {privateRoutes.map((r: RouteDefinition) =>
                <Route path={r.route} key={r.route} element={<LoggedInRoute>{r.element}</LoggedInRoute>} />,
            )}

            {/* Can only access logged in */}
            {adminRoutes.map((r: RouteDefinition) =>
                <Route path={r.route} key={r.route} element={<AdminRoute>{r.element}</AdminRoute>} />,
            )}

            {/* Not found page */}
            <Route path={"*"} element={notFoundRoute}></Route>
        </Routes>
    );
};
