import { PageWrapper } from "components/PageWrapper";

interface NotFoundPageAttrs {
    children?: JSX.Element;
    status?: number;
}

export const NotFoundPage = ({ children, status }: NotFoundPageAttrs): JSX.Element =>
    <PageWrapper header="Not Found!" subHeader={status} >
        {children}
    </PageWrapper>;
