export const configuration = {
    /**
     * @var Record<string,string> The keys for the local storage.
     */
    storageKey: {
        AUTH_TOKEN: "AUTH_TOKEN",
        USER: "USER",
        OPENAI_KEY: "OAIK",
        PRE_LOGIN: "PRE_LOGIN",
    },
    status: {
        /**
         * Getting any of these HTTP statuses will open a toast that allows you to resend the message.
         */
        GATE_TIMEOUT: [503, 504],
        /**
         * Getting any of these HTTP statuses will kick you out -> Unauthorized HTTP code
         */
        UNAUTHORIZED: [401, 403],
    },
    /**
     * @var number The size of the "small screen", same as the SCSS variable, for MUI.
     */
    smallScreenSize: /* px */ 800, // same as $screen-sm
    defaultUserSettings: {},
};
