import axios, { AxiosResponse } from "axios";
import { configuration } from "config/configuration";
import { _ENV_ } from "config/env";
import { defaultHeaders } from "functions/default-headers.function";
import { showToast } from "functions/show-toast.function";
import { routeUrls } from "routes/routes-urls";
import { Observable, catchError, from, tap, throwError } from "rxjs";
import { IAxiosServerResponse, AuthResponse } from "shared";
import { EndpointLabels } from "types/endpoint-labels.enum";

export const axiosRequest = <T, BodyType = T>(
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
    url: string,
    label: EndpointLabels,
    body?: BodyType,
    followUnauthorizedRedirects: boolean = true,
): Observable<T> => {
    return from<Promise<T>>(
        axios.request({
            url,
            method,
            baseURL: _ENV_.apiUrl,
            headers: defaultHeaders(),
            withCredentials: true,
            validateStatus: function (status: number) {
                return ![401, 400, 403, 500, 503, 422, 404].includes(status);
            },
            ...body ? { data: body } : {},
        })
            .then((r: AxiosResponse<T>) => r.data),
    )
        .pipe(
            tap((response: T) => {
                if (label !== "") {
                    // mixpanel.track(`API request to: (${method}) ${label}`, {
                    //     httpRequest: {
                    //         requestUrl: url,
                    //         method,
                    //         body,
                    //         response,
                    //     },
                    // });
                }
            }),
            catchError((e: any) => {
                // mixpanel.track(`API request [FAILED!] to: (${method}) ${label}`, {
                //     httpRequestError: {
                //         requestUrl: url,
                //         method,
                //         body,
                //         errorData: e.response.data,
                //     },
                // });
                if (
                    followUnauthorizedRedirects &&
                    configuration.status.UNAUTHORIZED.includes(e.response.status)
                ) {
                    window.location.href = routeUrls.login;
                    throw new Error(e);
                }

                console.error(e.response.data);
                if ((e as IAxiosServerResponse<AuthResponse>)?.response?.data?.message) {
                    const responseData: AuthResponse = e.response.data;
                    if ([503, 504].includes(responseData.status)) {
                        // catch it later in the component
                    } else {
                        showToast({
                            icon: "⛔️",
                            duration: responseData.alertTimeout ?? 6000,
                        }, responseData.reason ?? `ERROR ${responseData.status as number}, see console for details`);
                    }
                    return throwError(() => new Error(JSON.stringify(responseData), {}));
                }
                throw new Error(e);
            }),
        );
};
