import Title from "antd/es/typography/Title";
import styles from "./page-wrapper.module.scss";

interface PageWrapperAttrs {
    header?: string;
    subHeader?: string | number;
    children?: JSX.Element | JSX.Element[];
}

export const PageWrapper = ({ header, subHeader, children }: PageWrapperAttrs): JSX.Element => {
    return (
        <section className={styles.pageWrapper}>
            <div className={styles.box}>
                <div className={styles.headerBox}>
                    {/* Page header */}
                    <div className={styles.header}>
                        {header && <Title level={2}>{header}</Title>}
                        {subHeader && <Title level={2}>{subHeader}</Title>}
                    </div>

                    {children}
                </div>
            </div>
        </section>
    );
};
