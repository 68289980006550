import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { routeUrls } from "routes/routes-urls";
import { useEffect } from "react";
import { configuration } from "config/configuration";

interface LoggedInRouteAttrs {
    children: JSX.Element | JSX.Element[];
}

export const LoggedInRoute = ({ children }: LoggedInRouteAttrs): JSX.Element => {
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            localStorage.setItem(configuration.storageKey.PRE_LOGIN, window.location.pathname);
            navigate(routeUrls.notLoggedIn, { replace: true });
        }
    }, []);

    return (
        user
            ? <>{children}</>
            : <></>
    );
};
