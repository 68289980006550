import { HomePage } from "pages/HomePage";
import { PrivacyPage } from "pages/PrivacyPage";
import { TermsOfServicePage } from "pages/TermsOfServicePage";
import { routeUrls } from "routes/routes-urls";
import { RouteDefinitions } from "types";

export const publicRoutes: RouteDefinitions = [
    { route: routeUrls.homePage, element: <HomePage /> },
    { route: routeUrls.privacy, element: <PrivacyPage /> },
    { route: routeUrls.terms, element: <TermsOfServicePage /> },
    // { route: routeUrls.newBot, element: <NewBotPage /> },
    // { route: routeUrls.previewBot(":botId", ":messageId"), element: <BotFormPage /> },
    // { route: routeUrls.about, element: <LandingPage /> },
];
