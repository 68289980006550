import { createContext, useContext } from "react";
import { IAppContext } from "types";

export const AppContext = createContext<IAppContext>({
    data: {},
    isSmallScreen: false,
    setData: () => null,
});

export const useAppContext = (): IAppContext => {
    const context = useContext(AppContext);

    return context;
};
