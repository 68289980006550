import { Observable } from "rxjs";
import { axiosRequest } from "services/axios.service";
import { IUserSettings } from "shared";
import { EndpointLabels } from "types/endpoint-labels.enum";

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class SettingsService {
    public static getSettings = (): Observable<IUserSettings> => {
        return axiosRequest<IUserSettings>("GET", "/settings", EndpointLabels._);
    };
}
