import { configuration } from "config/configuration";
import { useAuth } from "context/AuthContext";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { routeUrls } from "routes/routes-urls";
import { LoginService } from "services/login.service";
import { LoginResponse } from "shared";

import { Button, Divider, Input } from "antd";
import { PageWrapper } from "components/PageWrapper";
import styles from "./login-page.module.scss";

export const metadata = {
    title: "Sign Up - Simple",
    description: "Page description",
};

export const SignupPage = (): JSX.Element => {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const { setUser } = useAuth();

    const handleSubmit = (e?: any): void => {
        e?.preventDefault();

        LoginService.signup({ email, name, password })
            .subscribe((data: LoginResponse) => {
                setUser(data.user);
                const redirectTo: string = localStorage.getItem(configuration.storageKey.PRE_LOGIN) ?? routeUrls.onSignUp;
                localStorage.removeItem(configuration.storageKey.PRE_LOGIN);
                navigate(redirectTo);
            });
    };

    return (
        <PageWrapper header={"Welcome back!"}>
            {/* Form */}
            <div className={styles.container}>
                <form onSubmit={handleSubmit}>
                    <div className="flex">
                        <div className="w-full mb-3">
                            <label className="" htmlFor="name">Name</label>
                            <Input
                                id="name"
                                type="text"
                                className=""
                                placeholder="Enter your name"
                                required
                                value={name}
                                onChange={e => { setName(e.target.value); }}
                            />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-full mb-3">
                            <label className="" htmlFor="email">Email</label>
                            <Input
                                id="email"
                                type="email"
                                className=""
                                placeholder="Enter your email address"
                                required
                                value={email}
                                onChange={e => { setEmail(e.target.value); }}
                            />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-full mb-3">
                            <label className="" htmlFor="password">Password</label>
                            <Input id="password"
                                type="password"
                                className=""
                                placeholder="Enter your password"
                                required
                                value={password}
                                onChange={e => { setPassword(e.target.value); }}
                            />
                        </div>
                    </div>
                    <Button
                        htmlType="submit"
                        type="primary"
                        block
                        size="large"
                        className="mt-3">
                        Sign up
                    </Button>
                </form>
                <Divider className="text-gray">Or</Divider>

                <div className="text-gray text-center mt-6">
                    Already have an account? <Link to={routeUrls.login} className="link">Log in</Link>
                </div>
            </div>
        </PageWrapper>
    );
};
