import { ConfigProvider } from "antd";
import { AppContext } from "context/AppContext";
import { screenSize } from "functions/screen-size.function";
import { Layout } from "layout/layout/Layout";
import { useEffect, useRef, useState } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "routes/app-routes";
import { IAppContext } from "types";
import { AuthWrapper } from "wrapper/AuthWrapper";

export const App = (): JSX.Element => {
    const timeout = useRef<any>(null);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
    const [appData, setAppData] = useState<IAppContext["data"]>({});

    useEffect(() => {
        screenSize((isSmall: boolean) => {
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                setIsSmallScreen(isSmall);
            }, 100);
        });
    }, []);

    const handleAppData = (newAppData: IAppContext["data"]): void => {
        setAppData({ ...appData, ...newAppData });
    };

    return (
        <ConfigProvider theme={{
            components: {
                Typography: {
                    sizeMarginHeadingVerticalStart: 30,
                    sizeMarginHeadingVerticalEnd: 20,
                    fontSizeHeading1: 100,
                    // lineHeightHeading1: 1,

                    fontSizeHeading2: 60,
                    // lineHeightHeading2: 1,

                    fontSizeHeading3: 40,
                    // lineHeightHeading3: 1,
                },
            },
        }}>

            <AppContext.Provider value={{ isSmallScreen, data: appData, setData: handleAppData }}>
                <BrowserRouter>
                    <AuthWrapper>
                        <Layout>
                            <AppRoutes />
                        </Layout>
                    </AuthWrapper>
                </BrowserRouter>
                <Toaster position="top-center" />
            </AppContext.Provider>
        </ConfigProvider>
    );
};

export default App;
