import { Link } from "react-router-dom";
import { routeUrls } from "routes/routes-urls";

interface LogoAttrs {
    showFullName?: boolean;
}
export const Logo = ({ showFullName }: LogoAttrs): JSX.Element => {
    return (
        <Link to={routeUrls.homePage} className="flex" aria-label="Cruip">
            {/* <svg className="w-8 h-8" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"> */}
            {/* <defs> */}
            {/* <radialGradient cx="21.152%" cy="86.063%" fx="21.152%" fy="86.063%" r="79.941%" id="footer-logo">
            <stop stopColor="#4FD1C5" offset="0%" />
            <stop stopColor="#81E6D9" offset="25.871%" />
            <stop stopColor="#338CF5" offset="100%" />
          </radialGradient> */}
            <img
                src="/images/logo.svg"
                alt="Anonymized logo"
                style={{ height: "2rem" }}
            />
            {showFullName && <span className="mt-3 text-blue-900">nonymized</span>}
            {/* </defs> */}
            {/* <rect width="32" height="32" rx="16" fill="url(#footer-logo)" fillRule="nonzero" /> */}
            {/* </svg> */}
        </Link>
    );
};
