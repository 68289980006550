import { NotFoundPage } from "pages/NotFoundPage";
import { useAuth } from "./AuthContext";

interface AdminRouteAttrs {
    children: JSX.Element | JSX.Element[];
}

export const AdminRoute = ({ children }: AdminRouteAttrs): JSX.Element => {
    const { isAdmin } = useAuth();

    return (
        isAdmin
            ? <>{children}</>
            : <><NotFoundPage status={403}><p>Insufficient permissions</p></NotFoundPage></>
    );
};
