import { configuration } from "config/configuration";

export const screenSize = (onChange: (isSmall: boolean) => any): void => {
    const smallWindow = configuration.smallScreenSize; // smaller than this, and it's considered small.

    (window as any).isSmallScreen = false;

    window.onresize = function () {
        (window as any).isSmallScreen = (window.innerWidth < smallWindow);
        onChange((window.innerWidth < smallWindow));
    };
    (window as any).onresize();
};
