
import styles from "./layout.module.scss";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Footer } from "components/ui/footer";
import { Header } from "components/ui/Header";

interface LayoutProps {
    children: JSX.Element | Array<JSX.Element | undefined | null>;
    noPadding?: boolean;
}

export const Layout = ({ children }: LayoutProps): JSX.Element => {
    useEffect(() => {
        AOS.init({
            once: true,
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    });

    return (
        <>
            <div className={styles.layout}>
                <div className="Simpleflex">
                    {/* <div className="Simpleflex flex-col min-h-screen overflow-hidden supports-[overflow:clip]:overflow-clip"> */}
                    <Header />
                    <main className={styles.main}>
                        {children}
                    </main>
                    <Footer />

                </div>
            </div>

        </>
    );
};
