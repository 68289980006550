import { Logo } from "./logo";
import styles from "./footer.module.scss";
import { routeUrls } from "routes/routes-urls";
import { Link } from "react-router-dom";

export const Footer = (): JSX.Element => {
    return (
        <footer>
            <div className={styles.box}>

                {/* Top area: Blocks */}
                <div className={styles.blocks}>

                    {/* 1st block */}
                    <div className={styles.block}>
                        <div className="mb-2">
                            <Logo />
                        </div>
                        <div className={styles.termLinks}>
                            <Link to={routeUrls.terms}
                                target="_blank"
                                className={styles.termLink}
                            >
                                Terms
                            </Link>
                            {" · "}
                            <Link to={routeUrls.privacy}
                                target="_blank"
                                className={styles.termLink}
                            >
                                Privacy Policy
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Bottom area */}
                <div className={styles.footerBottom}>
                    {/* Copyrights note */}
                    <div className="text-sm">&copy; Anonymized.app. All rights reserved.</div>

                </div>

            </div>
        </footer>
    );
};
